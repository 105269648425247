interface ImageCardProps {
  src: string;
  alt: string;
  className?: string;
}

export function ImageCard({ src, alt, className }: ImageCardProps) {
  return (
    <img
      src={src}
      alt={alt}
      className={className ?? 'w-[10.5rem] h-[8.25rem] lg:w-84  lg:h-[12.5rem] rounded-[1.25rem] object-fill'}
    />
  );
}
