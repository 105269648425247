import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import Carousel from '@components/molecules/carousel/carousel';
import { logosCarousel } from '@components/utils/helper';

import style from './style.module.css';

const CustomersSection: React.FC = () => {
  const isSmallScreen = useScreenSize();
  const CustomerSection = twMerge(isSmallScreen ? 'py-10' : 'py-20', style.CustomerSection);
  const HeadingTitle = twMerge(
    isSmallScreen ? 'text-heading-xSmall' : 'text-heading-medium',
    'relative font-bold text-center',
  );

  return (
    <section className={CustomerSection} aria-labelledby="customers-section-heading">
      <div className={style.CustomerContainer}>
        <p id="customers-section-heading" className={HeadingTitle}>
          Somos uma gestora de energia aprovada por
          <span className="text-brand-primary-60"> líderes </span>
          do mercado
        </p>
        <Carousel logos={logosCarousel} />
      </div>
    </section>
  );
};

export default CustomersSection;
