import config from '@config';
import { ErrorPasswordTestComponent } from '@pages/ErrorPasswordTestComponent';
import { Outlet } from 'react-router-dom';

export function ProtectedRoutes() {
  const params = new URLSearchParams(window.location.search);
  const password = params.get('senha');

  const isAuthorized = password === config.CLARKE_TEST_PASSWORD;

  return isAuthorized ? <Outlet /> : <ErrorPasswordTestComponent />;
}
