import mockupBolt from '@assets/RAIO+FOTO.png';
import { FooterSection } from '@components/atoms/home/conversion-booster/footer-section';
import { HeaderSection } from '@components/atoms/home/conversion-booster/header-section';
import { useScreenSize } from '@hooks/useScreenSize';

const BodySection = ({ children, isSmallScreen }: { children: React.ReactNode; isSmallScreen?: boolean }) =>
  isSmallScreen ? (
    <div className="flex flex-col-reverse lg:flex-row lg:justify-center items-center h-full">{children}</div>
  ) : (
    <>{children}</>
  );

export function ConversationBoosterContainer() {
  const isSmallScreen = useScreenSize();
  return (
    <section
      className="w-full h-fit p-1 grid grid-cols-1 md:grid-cols-3 lg:h-fit bg-[linear-gradient(260deg,#00DF7C_2.4%,#2E2E2E_91.54%)] xl:p-5 lg:px-20"
      aria-label="Conversation Booster"
    >
      <div className="col-span-full w-full mx-auto min-h-fit max-w-screen-xl ml-auto flex flex-col lg:flex-row gap-4 xl:justify-between xl:px-8 2xl:px-16">
        <div className="w-[24.0625rem] px-8 lg-:px-0 lg:min-h-full">
          <HeaderSection />
        </div>
        {isSmallScreen ? (
          <BodySection isSmallScreen={isSmallScreen}>
            <img
              src={mockupBolt}
              alt="Pessoal sorridente com um raio de energia"
              className="w-96 h-96 lg:h-[36.25rem] lg:min-w-[32.3125rem] ml-2"
            />
            <FooterSection />
          </BodySection>
        ) : (
          <BodySection>
            <div className="flex justify-center items-center h-full w-full xl:w-40 relative">
              <img
                src={mockupBolt}
                alt="Pessoal sorridente com um raio de energia"
                className="lg:absolute lg:-left-16 xl:-left-44 w-96 h-96 xl:min-h-[36.25rem] lg:min-w-[36.3125rem] object-fill"
              />
            </div>
            <FooterSection />
          </BodySection>
        )}
      </div>
    </section>
  );
}
