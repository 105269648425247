import withClarkeImage from '@assets/with-clarke.png';
import withoutClarkeImage from '@assets/without-clarke.png';

import { CardComponent } from '@components/atoms/with-and-without-solution/card-component';

import styles from './style.module.css';
import { cardData } from './helper';

export function WithAndWithoutSolution() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>A Clarke é a primeira gestora independente no Brasil</div>
      <div className={styles.cardsContainer}>
        <CardComponent
          title={
            <>
              O Mercado Livre <br /> sem a Clarke
            </>
          }
          bgColor="bg-[#666666]/60"
          textColor="text-white"
          image={[
            {
              src: withoutClarkeImage,
              styles: 'w-[17.264375rem] h-[15.625rem] relative',
              alt: 'Ilustração de lâmpada, moedas, relógio e recibo, simbolizando gestão de tempo, ideias ou planejamento financeiro.',
            },
          ]}
          heroIcon={{ icon: 'XCircleIcon', type: 'outline', extraClassNames: 'h-6 w-6 text-[#ff6347]' }}
          description={cardData.withoutClarke}
        />
        <CardComponent
          title={
            <>
              O Mercado Livre <br /> com a Clarke
            </>
          }
          bgColor="bg-[#e5f8dc]"
          textColor="text-black"
          image={[
            {
              src: withClarkeImage,
              styles: 'w-[17.264375rem] h-[15.625rem] relative',
              alt: 'Ilustração de mão clicando em laptop, gráfico de barras, moedas em pote e estrelas, representando finanças ou análise de dados.',
            },
          ]}
          heroIcon={{ icon: 'CheckCircleIcon', type: 'outline', extraClassNames: 'h-6 w-6 text-[#008c4c] ' }}
          description={cardData.withClarke}
        />
      </div>
    </div>
  );
}
