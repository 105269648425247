import { twMerge } from 'tailwind-merge';

interface InfoCardProps {
  value: number;
  text: string;
  tagClass?: Record<string, string>;
}

export function InfoCard({ value, text, tagClass }: InfoCardProps) {
  return (
    <div className="flex justify-center w-[10.5rem] h-[8.25rem] md:w-84 md:h-[12.5rem] flex-col lg:items-start gap-1 shrink-0 bg-[#F2F2F2] lg:px-4 py-6 rounded-[1.25rem]">
      <strong
        className={twMerge(
          'text-brand-primary-60 text-center text-3xl md:text-[4.6875rem] font-extrabold leading-normal',
          tagClass?.strong,
        )}
      >
        +{value}
      </strong>
      <p
        className={twMerge(
          'text-[#222] text-center lg:text-start text-paragraph-small lg:text-paragraph-large font-light leading-normal w-40 lg:w-full',
          tagClass?.paragraph,
        )}
      >
        {text}
      </p>
    </div>
  );
}
