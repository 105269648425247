import { twMerge } from 'tailwind-merge';
import HeroIcon, { IconName } from '@components/atoms/hero-icon';
import './style.module.css';

interface CardComponentProps {
  title: JSX.Element | string;
  bgColor: string;
  textColor: string;
  image: { src: string; styles: string; alt?: string }[];
  heroIcon: { icon: IconName; type: 'outline' | 'solid'; extraClassNames: string };
  description: string[];
}

export const CardComponent = ({
  title,
  bgColor,
  textColor,
  image,
  heroIcon,
  description,
}: CardComponentProps): JSX.Element => {
  return (
    <div className={twMerge(`card-container ${bgColor}`)}>
      <div className="card-image-container">
        <div className="card-image" />
        {image.map((img, index) => (
          <img key={index} className={twMerge(`absolute ${img.styles}`)} src={img.src} alt={img.alt || 'Image'} />
        ))}
      </div>
      <div className={twMerge(`card-title ${textColor}`)}>{title}</div>
      <div>
        {description.map((desc, index) => (
          <div key={index} className="card-description-container">
            <div className="card-description-icon">
              <HeroIcon icon={heroIcon.icon} type={heroIcon.type} extraClassNames={heroIcon.extraClassNames} />
            </div>
            <div className={twMerge('card-description-text', textColor)}>{desc}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
