import { twMerge } from 'tailwind-merge';

export function ConstructingArticleContainer({
  mockupName,
  altText,
  strongTitle,
  strongTextJSX,
  paragraphText,
  reveserLayout,
  width = 'lg:w-[31.25rem]',
  height = 'lg:h-[21.875rem]',
}: {
  mockupName: string;
  altText: string;
  strongTitle: string;
  strongTextJSX: JSX.Element;
  paragraphText: string;
  reveserLayout: boolean;
  width?: string;
  height?: string;
}) {
  return (
    <div
      className={twMerge(
        'flex flex-col-reverse lg:flex-row gap-x-28 gap-y-2 mt-8 justify-center items-center p-3',
        reveserLayout && 'lg:flex-row-reverse',
      )}
    >
      <article
        className={twMerge('flex flex-col min-w-[20.875rem] lg:w-[31.25rem] gap-4 lg:justify-around', width, height)}
      >
        <strong className=" text-heading-medium lg:text-heading-xLarge">{strongTitle}</strong>
        {strongTextJSX}
        <p className="text-paragraph-medium">{paragraphText}</p>
      </article>
      <img className={twMerge(width ?? 'w-[31.25rem]', height ?? 'h-[21.875rem]')} src={mockupName} alt={altText} />
    </div>
  );
}
