import noteMockup from '@assets/image (6).png';
import doubleSmartphoneMockup from '@assets/image (7).png';
import tabletMockup from '@assets/image (8).png';
import smartphoneMockup from '@assets/image (9).png';
import { ConstructingArticleContainer } from '@components/atoms/home/pain-vs-feature/article-content';

export function PainVsFeatureContainer() {
  return (
    <section className="w-full h-full bg-neutral-50 py-8 flex justify-center items-center">
      <div className="max-w-screen-xl w-full h-fit py-10 bg-neutral-50 flex-col justify-start items-center lg:gap-20 inline-flex">
        <div className="w-full text-center">
          <span className="text-[#222222] text-[2rem] font-bold font-['Poppins']">
            Com a Clarke, o Mercado Livre de Energia é{' '}
          </span>
          <span className="text-[#00bf6a] text-[2rem] font-bold font-['Poppins']">mais fácil</span>
        </div>

        <ConstructingArticleContainer
          mockupName={noteMockup}
          altText="Note Mockup"
          strongTitle="Não sabe como escolher o fornecedor para a sua empresa?"
          strongTextJSX={
            <strong className="w-full">
              Economize ainda mais com o <b className="text-[#00bf6a]">primeiro marketplace de energia</b> livre!
            </strong>
          }
          paragraphText="Em nosso marketplace próprio, a sua empresa recebe dezenas de proposta em menos de 24 horas! Elas são ranqueadas por diferentes critérios como preço e qualidade do fornecedor, e, com essas informações, indicamos as melhores opções para seu consumo."
          reveserLayout={false}
        />

        <ConstructingArticleContainer
          mockupName={doubleSmartphoneMockup}
          altText="Double Smartphone Mockup"
          strongTitle="A Migração para o Mercado Livre de Energia parece complexa e burocrática?"
          strongTextJSX={
            <strong className="w-full">
              A Clarke cuida <b className="text-[#00bf6a]">100% do processo</b> de migração para você
            </strong>
          }
          paragraphText="Na Clarke, como gestora independente, promovemos negociações justas e transparentes para garantir o melhor resultado aos nossos clientes. Além da migração para o mercado livre de energia, também acompanhamos as flutuações de preço para a sua empresa garantir as melhores oportunidades ao longo do tempo."
          reveserLayout={true}
        />

        <ConstructingArticleContainer
          mockupName={tabletMockup}
          altText="Tablet Mockup"
          strongTitle="Como saber se estamos realmente economizando?"
          strongTextJSX={
            <strong className="w-full">
              <b className="text-[#00bf6a]">Descomplique</b> o assunto energia na sua empresa
            </strong>
          }
          paragraphText="Com atendimento e tecnologia personalizados, você entenderá exatamente o que está contratando, quais serão as necessidades, processos e resultados. Além disso, sua empresa receberá relatórios de performance no Mercado Livre de Energia para saber quanto está economizando."
          reveserLayout={false}
        />

        <ConstructingArticleContainer
          mockupName={smartphoneMockup}
          altText="Smartphone Mockup"
          strongTitle="Como associar economia a sustentabilidade e usar isso a favor da sua empresa?"
          strongTextJSX={
            <strong className="w-full">
              Melhore seus indicadores de sustentabilidade consumindo energia{' '}
              <b className="text-[#00bf6a]">100% renovável</b>
            </strong>
          }
          paragraphText="A Clarke só trabalha com fornecedores de energia limpa. Ao contratar energia através da Clarke, sua empresa automaticamente reduzirá a pegada de carbono e você receberá relatórios detalhados sobre o CO2 evitado, melhorando assim sua pontuação em critérios ESG."
          reveserLayout={true}
        />
      </div>
    </section>
  );
}
