import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import NewsLetterForm from '@components/atoms/form-newsletter';

import ThunderImg from '@assets/clarke-lightning-3.png';

import style from './style.module.css';

const NewsletterSection = () => {
  const isSmallScreen = useScreenSize();
  const NewsletterContainer = twMerge(
    style.NewsletterContainer,
    isSmallScreen ? 'flex-col py-8 ' : 'flex-row px-20 items-center gap-6',
  );
  const formStyle = twMerge(
    isSmallScreen ? 'px-4 py-5 w-full' : 'px-[1.375rem] pt-[1.9375rem] min-w-[35rem]',
    'bg-brand-primary-30 rounded-medium pb-4',
  );
  const NewsletterContentTitle = twMerge(
    isSmallScreen ? 'text-heading-small px-4 w-full' : 'text-heading-medium w-[24.6875rem]',
    style.NewsletterContentTitle,
  );
  const NewsletterContentDescription = twMerge(
    isSmallScreen
      ? 'w-full text-paragraph-small px-4 w-full mt-3 mb-[1.0625rem]'
      : 'text-paragraph-large w-[26.375rem]',
    style.NewsletterContentDescription,
  );
  const NewsletterContent = twMerge(
    style.NewsletterContent,
    isSmallScreen ? 'flex-col' : 'justify-center h-[22.3125rem] ',
  );

  return (
    <section className={style.NewsletterSection}>
      <div className={NewsletterContainer}>
        {isSmallScreen && (
          <img
            src={ThunderImg}
            alt="Ilustração de um raio verde"
            loading="lazy"
            decoding="async"
            aria-hidden="true"
            className="absolute w-[37.03rem] h-[38.6875rem] object-cover z-0 -top-14"
          />
        )}
        <div className={NewsletterContent}>
          <div className={twMerge(isSmallScreen ? 'relative' : 'z-30 absolute')}>
            <h2 className={NewsletterContentTitle}>Quer aprender mais sobre o mercado livre de energia?</h2>
            <p className={NewsletterContentDescription}>
              Se inscreva na nossa newsletter e receba insights exclusivos, análises especializadas e atualizações do
              setor diretamente na sua caixa de entrada.
            </p>
          </div>
          {!isSmallScreen && (
            <img
              src={ThunderImg}
              alt="Ilustração de um raio verde"
              loading="lazy"
              decoding="async"
              aria-hidden="true"
              className="absolute w-[37.03rem] h-[38.6875rem] object-cover z-0 -top-32 left-0"
            />
          )}
        </div>
        <div className={twMerge(isSmallScreen ? 'px-4 w-full' : '', 'z-10')}>
          <NewsLetterForm formStyle={formStyle}></NewsLetterForm>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSection;
