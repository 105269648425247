interface FooterSectionProps {
  worldImage: string;
}

export function FooterSection({ worldImage }: FooterSectionProps) {
  return (
    <div className="flex justify-center items-center gap-20 pb-8">
      <div className="px-2 md:p-12 flex flex-col md:flex-row w-full md:w-[79.9375rem] h-auto md:h-[29.875rem]">
        <div className="flex-1 max-w-full md:max-w-[40.625rem] mt-4 md:mt-14">
          <h1 className="text-start self-stretch text-heading-large lg:text-[2.5rem]  font-bold leading-normal">
            São mais de <span className="text-green-500">75 mil toneladas</span> de CO² evitados por ano
          </h1>
          <p className="mt-4 text-paragraph-medium lg:w-[28.25rem]">
            Caso esse CO² fosse emitido, seriam necessários mais de 30 mil árvores na Mata Atlântica para compensá-lo.
          </p>
          <p className="mt-4 text-sm text-gray-500">Fonte: SOS Mata Atlântica</p>
        </div>
        <div className="relative h-96 w-96">
          <img
            className="absolute bottom-0 -left-10 lg:left-0 lg:-right-10"
            src={worldImage}
            alt="Illustration of a globe with a power plug and green leaves"
          />
        </div>
      </div>
    </div>
  );
}
