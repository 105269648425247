import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';
import HeroIcon from '@components/atoms/hero-icon';

import style from './style.module.css';

const CompetitiveAdvantages = () => {
  const isSmallScreen = useScreenSize();
  const CompetitiveSection = twMerge(isSmallScreen ? 'px-4 py-8' : 'p-20', style.CompetitiveSection);
  const CompetitiveContainer = twMerge(
    isSmallScreen ? 'flex-col' : 'flex-row px-20 items-center',
    style.CompetitiveContainer,
  );
  const CompetitiveHeader = twMerge(isSmallScreen ? 'w-full' : 'w-[28.3125rem]', style.CompetitiveHeader);
  const CompetitiveHeaderTitle = twMerge(
    isSmallScreen ? 'text-heading-small leading-normal text-center' : 'text-heading-xLarge leading-[3rem] text-left',
    'font-regular',
  );
  const CompetitiveList = twMerge(isSmallScreen ? 'pt-10' : 'pt-0', style.CompetitiveList);
  const CompetitiveListItem = twMerge(isSmallScreen ? 'w-full' : 'w-[33.625rem]', style.CompetitiveListItem);
  const CompetitiveTitle = twMerge(
    isSmallScreen ? 'text-heading-xSmall' : 'text-heading-medium',
    style.CompetitiveTitle,
  );
  const CompetitiveDescription = isSmallScreen ? 'text-paragraph-small' : 'text-paragraph-medium';
  const CompetitiveIconBlock = twMerge(isSmallScreen ? 'h-11 w-11' : 'h-16 w-16', 'relative');
  const CompetitiveIconBack = twMerge(isSmallScreen ? 'h-11 w-11' : 'h-16 w-16', 'text-brand-light-20');
  const CompetitiveIconFront = twMerge(isSmallScreen ? 'h-11 w-11' : 'h-16 w-16', style.CompetitiveIconFront);

  return (
    <section className={CompetitiveSection}>
      <div className={CompetitiveContainer}>
        <div className={CompetitiveHeader}>
          <h2 className={CompetitiveHeaderTitle}>
            A <span className="text-brand-primary-60 font-bold">Clarke</span> é o caminho mais
            <span className="text-brand-primary-60"> simples</span>,
            <span className="text-brand-primary-60"> barato</span> e
            <span className="text-brand-primary-60"> seguro </span>
            para comprar energia limpa e mais barata.
          </h2>
        </div>
        <div className={CompetitiveList}>
          <div className={CompetitiveListItem}>
            <div className={CompetitiveIconBlock}>
              <HeroIcon type="solid" icon="ChatBubbleOvalLeftIcon" extraClassNames={CompetitiveIconBack} />
              <HeroIcon
                type="outline"
                icon="ChatBubbleOvalLeftIcon"
                extraClassNames={twMerge(style.CompetitiveIconFrontFirst, CompetitiveIconFront)}
              />
            </div>
            <div className={isSmallScreen ? 'ml-4' : 'ml-5'}>
              <h3 className={CompetitiveTitle}>Consultoria em energia</h3>
              <p className={CompetitiveDescription}>
                Conte com o time de especialistas da Clarke para guiar as decisões de energia da sua empresa.
              </p>
            </div>
          </div>

          <div className={CompetitiveListItem}>
            <div className={CompetitiveIconBlock}>
              <HeroIcon type="solid" icon="ShieldCheckIcon" extraClassNames={CompetitiveIconBack} />
              <HeroIcon
                type="outline"
                icon="ShieldCheckIcon"
                extraClassNames={twMerge(style.CompetitiveIconFrontSecond, CompetitiveIconFront)}
              />
            </div>
            <div className="ml-5 text-brand-light-20">
              <h3 className={CompetitiveTitle}>Segurança</h3>
              <p className={CompetitiveDescription}>
                Tenha visibilidade do risco de cada fornecedor e tome decisões seguras nos contratos de energia.
              </p>
            </div>
          </div>

          <div className={CompetitiveListItem}>
            <div className={CompetitiveIconBlock}>
              <HeroIcon type="solid" icon="CodeBracketSquareIcon" extraClassNames={CompetitiveIconBack} />
              <HeroIcon
                type="outline"
                icon="CodeBracketSquareIcon"
                extraClassNames={twMerge(style.CompetitiveIconFrontSecond, CompetitiveIconFront)}
              />
            </div>
            <div className="ml-5 text-brand-primary-70">
              <h3 className={CompetitiveTitle}>Tecnologia</h3>
              <p className={CompetitiveDescription}>
                Tenha todos os dados relevantes sobre sua energia na palma da sua mão, de maneira rápida e fácil.
              </p>
            </div>
          </div>

          <div className={CompetitiveListItem}>
            <div className={CompetitiveIconBlock}>
              <HeroIcon type="solid" icon="DocumentCheckIcon" extraClassNames={CompetitiveIconBack} />
              <HeroIcon
                type="outline"
                icon="DocumentCheckIcon"
                extraClassNames={twMerge(style.CompetitiveIconFrontSecond, CompetitiveIconFront)}
              />
            </div>
            <div className="ml-5 text-brand-primary-70">
              <h3 className={CompetitiveTitle}>Rescisão facilitada</h3>
              <p className={CompetitiveDescription}>
                Somos a única gestora do país que te oferece um contrato sem multa de rescisão - apenas um aviso prévio
                de 3 meses.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompetitiveAdvantages;
