export const cardData = {
  withoutClarke: [
    'Cotação de energia com poucos fornecedores consultados;',
    'Preços pouco diferentes entre os fornecedores ou sem apontar a real concorrência do ACL;',
    'Contratos de gestão e energia vinculados, tirando a liberdade do consumidor;',
    'Multas abusivas para encerramento de contrato;',
    'Processos analógicos e atendimento lento e ineficiente;',
    'Atendimento generalista que não se aprofunda na dor de cada cliente;',
    'Renovação de contrato no vencimento, sem cuidado com o preço ou necessidades do cliente;',
    'Ilusão de "custo zero" de migração ou gestão - aumentando o preço da sua energia.',
  ],
  withClarke: [
    'Pregão operado pela Clarke, com mais de 70 fornecedores disponíveis para concorrer pela sua empresa;',
    'Menor preço do mercado livre de energia;',
    'Gestora independente, que trabalha com foco no melhor resultado para cada cliente;',
    'Encerramento de contrato sem multa com 90 dias de aviso prévio;',
    'Tecnologia real e aplicada com foco no resultado e experiência do cliente;',
    'Atendimento especializado e humanizado no dia a dia;',
    'Gestores ativos e atentos ao preço para sua renovação ocorrer no melhor momento;',
    'Energia 100% limpa sem custo adicional.',
  ],
};
