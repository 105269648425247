import { useState } from 'react';
import { ButtonSelector } from '@components/atoms/home/context-section/button';
import { SectionContent } from '@components/atoms/home/context-section/section-content';
import { ImageSection } from '@components/atoms/home/context-section/image-section';
import { sectionsDetails } from './helper';

export function ContextSectionContainer() {
  const [sectionStep, setSectionStep] = useState(0);

  const handleSectionStep = (index: number) => {
    setSectionStep(index);
  };

  const currentSection = sectionsDetails[sectionStep];

  return (
    <div className="flex flex-col gap-10 bg-neutral-50 w-full h-full lg:w-[79.9375rem] lg:h-[55.3125rem] lg:mx-auto lg:p-20">
      <div className="text-center text-4xl font-bold">
        <h1 className="w-full text-center lg:px-36">
          {' '}
          Soluções em energia altamente eficazes para a realidade da sua empresa
        </h1>
      </div>
      <div className="flex flex-col lg:flex-row gap-4 justify-center items-center p-2">
        {sectionsDetails.map((section, index) => (
          <ButtonSelector
            key={index}
            isActive={index === sectionStep}
            label={section.label}
            onClick={() => handleSectionStep(index)}
          />
        ))}
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-4">
        <div className="">
          <ImageSection src={currentSection.content.image} alt={currentSection.label} />
        </div>
        <div className="lg:relative w-full">
          <SectionContent
            description={currentSection.content.description}
            highlights={currentSection.content.highlights}
          />
        </div>
      </div>
    </div>
  );
}
