import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import ButtonComponent from '@components/atoms/button/button';
import { LinksContent } from '@components/utils/helper';

import WorldImage from '@assets/world.png';

import style from './style.module.css';

const FreeFutureSection = () => {
  const isSmallScreen = useScreenSize();
  const wantToKnowMoreLink = LinksContent[2];
  const freeFutureSection = twMerge(isSmallScreen ? 'px-4 py-10' : 'p-20', style.freeFutureSection);
  const freeContainer = twMerge(isSmallScreen ? 'p-0' : 'px-20', style.freeFutureContainer);
  const freeContent = twMerge(isSmallScreen ? 'w-full' : 'max-w-[40.56rem', 'flex flex-row');
  const freeTitle = twMerge(
    isSmallScreen
      ? 'text-heading-medium w-full leading-[2rem]'
      : 'text-heading-xxLarge mb-8 w-[41.94rem] leading-[3rem]',
    style.freeTitle,
  );
  const freeDescription = twMerge(
    isSmallScreen ? 'text-paragraph-medium w-full' : 'text-paragraph-large w-[34.94rem]',
    style.freeDescription,
  );
  const freeFutureBtn = twMerge(isSmallScreen ? 'w-full flex justify-between h-12' : 'w-fit h-14', style.freeFutureBtn);

  return (
    <section className={freeFutureSection} aria-labelledby="future-challenge-heading">
      <div className={freeContainer}>
        <div className={freeContent}>
          <div>
            <h2 id="future-challenge-heading" className={freeTitle}>
              O <span className="text-brand-primary-60">futuro é livre</span> e você também pode fazer parte dele
            </h2>
            {isSmallScreen && (
              <img
                src={WorldImage}
                alt="Ilustração de um globo terrestre sustentavel"
                className="w-[25.875rem] mx-auto"
                aria-hidden="true"
                loading="lazy"
                decoding="async"
              />
            )}
            <div className={freeDescription}>
              <p>
                A abertura do mercado livre de energia levanta perspectivas sobre um futuro cada vez mais livre e
                sustentável. É esperado que cada vez mais consumidores, com contas mais baixas, tenham a chance de
                escolher o seu fornecedor elétrico.
              </p>
              <p>
                O MLE também permite que o consumidor opte por fornecedores de fontes renováveis de energia, sendo um
                agente crucial no processo de descarbonização da economia.
              </p>
              <p>
                Porém, o Mercado Livre de Energia ainda é rodeado por desafios. Suas complexidades regulatórias e
                burocráticas requerem conhecimento técnico para que os consumidores livres possam alcançar os melhores
                preços e contratos com os fornecedores.
              </p>
              <p>
                Por isso, o recomendado é contratar uma gestora elétrica, com a expertise de enxergar as melhores
                oportunidades para a sua empresa e potencializar seus lucros.
              </p>
              <ButtonComponent
                icon="ArrowRightIcon"
                iconPosition="right"
                iconType="solid"
                iconStyles={twMerge(isSmallScreen ? 'w-8 h-8' : 'w-10 h-10', 'bg-brand-light-20 text-brand-primary-70')}
                kind="primaryCustom"
                size="2"
                labelSize="w-full ml-2"
                label="Quero saber mais"
                onClick={() => window.open(wantToKnowMoreLink.src, wantToKnowMoreLink.typeOfWindow)}
                className={freeFutureBtn}
              />
            </div>
          </div>
          {!isSmallScreen && (
            <img
              src={WorldImage}
              alt="Ilustração de um globo terrestre sustentavel"
              aria-hidden="true"
              className={style.imageDesktop}
              loading="lazy"
              decoding="async"
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default FreeFutureSection;
