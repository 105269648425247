import { InfoCard } from '@components/atoms/home/big-numbers/info-card';
import { ImageCard } from '@components/atoms/home/big-numbers/image-card';
import { FooterSection } from '@components/atoms/home/big-numbers/footer-section';
import mockupIPhone from '@assets/iPhone 13 Pro.png';
import mockupIPhoneMobile from '@assets/iPhone 13 Pro Mobile.png';
import windTurbineImage from '@assets/image 11.png';
import windTurbineImageMobile from '@assets/image 11 Mobile.png';
import lampImage from '@assets/image 12.png';
import lampImageMobile from '@assets/image 12 Mobile.png';
import worldImage from '@assets/rb_2451 1.png';
import { useScreenSize } from '@hooks/useScreenSize';

function LargeScreenLayout({
  companies,
  comparedEnergy,
  consumerUnits,
}: {
  companies: number;
  comparedEnergy: number;
  consumerUnits: number;
}) {
  return (
    <div className="flex flex-col gap-8 justify-center 2xl:items-center max-w-screen-xl mx-auto">
      <div className="flex">
        <div className="flex flex-wrap w-[46rem] min-h-full  items-center gap-x-2 gap-y-8">
          <span className="flex justify-center items-center  w-[46rem]  h-[12.5rem] rounded-medium bg-brand-primary-60 text-white">
            <h2 className="w-[40.75rem] text-white text-xl text-start md:text-4xl font-bold leading-normal">
              A Clarke Energia é a maior gestora independente de energia do Brasil
            </h2>
          </span>
          <div className="flex justify-around gap-8">
            <img src={mockupIPhone} alt="iPhone 13 Pro" className=" w-84 h-[26.75rem]" />
            <div className="flex flex-col gap-8">
              <InfoCard value={comparedEnergy} text="MWh de energia comprada com a assessoria da Clarke" />
              <ImageCard src={lampImage} alt="Lâmpada" />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-8 justify-center items-center w-1/3">
          <span className="flex justify-center w-84 h-[12.5rem] flex-col items-start gap-1 shrink-0 bg-[#F2F2F2] px-4 py-6 rounded-[1.25rem]">
            <strong className="text-brand-primary-60 text-center text-[4.6875rem] font-extrabold leading-normal">
              +{companies}
            </strong>
            <p className="text-[#222] text-center text-lg font-light leading-normal">empresas confiam na Clarke</p>
          </span>
          <div className="flex flex-col-reverse gap-8">
            <InfoCard value={consumerUnits} text="Unidades Consumidoras geridas pelo nosso time de especialistas" />
            <ImageCard src={windTurbineImage} alt="Turbina eólica" />
          </div>
        </div>
      </div>

      <FooterSection worldImage={worldImage} />
    </div>
  );
}

function SmallScreenLayout({
  companies,
  comparedEnergy,
  consumerUnits,
}: {
  companies: number;
  comparedEnergy: number;
  consumerUnits: number;
}) {
  return (
    <>
      <span className="flex items-center w-[21.5rem] h-auto rounded-medium bg-brand-primary-60 text-white p-4">
        <h2 className="w-full text-white text-xl text-center font-bold leading-normal">
          A Clarke Energia é a maior gestora independente de energia do Brasil
        </h2>
      </span>

      <div className="grid grid-cols-2 gap-2">
        <InfoCard
          tagClass={{
            paragraph: 'text-paragraph-medium font-light leading-normal w-40 ml-2 px-2 text-center',
          }}
          value={companies}
          text="empresas confiam na Clarke"
        />
        <ImageCard src={windTurbineImageMobile} alt="Turbina eólica" />
        <ImageCard src={lampImageMobile} alt="Lâmpada" />
        <InfoCard value={comparedEnergy} text="MWh de energia comprada com a assessoria da Clarke" />
        <InfoCard value={consumerUnits} text="Unidades Consumidoras geridas pelo nosso time de especialistas" />
        <div className="px-2">
          <img src={mockupIPhoneMobile} alt="iPhone 13 Pro" className="w-[10.5rem] h-[8.25rem] object-fill" />
        </div>
      </div>
      <FooterSection worldImage={worldImage} />
    </>
  );
}

export function BigNumbersContainer() {
  const isSmallScreen = useScreenSize();
  const data = { companies: 800, comparedEnergy: 400, consumerUnits: 900 };

  return (
    <section
      className="max-w-screen-xl flex flex-col gap-8 px-8 lg:pl-14 justify-center lg:mx-auto"
      aria-label="Big Numbers"
    >
      {isSmallScreen ? <SmallScreenLayout {...data} /> : <LargeScreenLayout {...data} />}
    </section>
  );
}
