import React from 'react';

export const ErrorPasswordTestComponent: React.FC = () => {
  return (
    <div className="flex overflow-y-auto flex-col justify-center items-center h-screen align-middle md:flex-row md:px-36 bg-slate-50">
      <div className="flex flex-col justify-start items-start pl-10 mx-20 text-left align-middle border-l-2 border-stone-700">
        <div className="font-semibold text-4xl">Ops! Credenciais inválidas...</div>
        <div className="mt-2 w-full text-base font-light text-neutral-60">
          Você não tem permissão necessária para acessar essa página.
        </div>
      </div>
    </div>
  );
};
