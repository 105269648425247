import { LinksContent } from '@components/utils/helper';
import { Link } from 'react-router-dom';

export function FooterSection() {
  const talkToEnergySpecialistLink = LinksContent[0];

  return (
    <span className="bg-transparent px-4 lg:-ml-0 xl:-ml-8 lg:py-10 lg:px-0  xl:py-20 w-[24.0625rem]  flex flex-col justify-start items-start lg:justify-end lg:items-start gap-1">
      <strong className="text-neutral-50 lg:text-center text-nowrap text-paragraph-large lg:text-heading-small font-bold leading-normal">
        Você já está no Mercado Livre de Energia?
      </strong>
      <span className="text-white text-paragraph-medium leading-normal w-9/12 lg:w-[24.0625rem] lg:z-50">
        <Link to={talkToEnergySpecialistLink.src} target="_blank" className="text-white underline cursor-pointer">
          Fale com um especialista da Clarke
        </Link>{' '}
        e descubra como sua empresa pode ter mais resultados.
      </span>
    </span>
  );
}
