import { twMerge } from 'tailwind-merge';

import { useModalContext } from '@contexts/modalContext';
import { useScreenSize } from '@hooks/useScreenSize';

import ButtonComponent from '@components/atoms/button/button';
import { CardComponent } from '@components/atoms/home/free-energy-market-section/card-component';
import { freeEnergyMarketCardData } from './helper';
import style from './style.module.css';
import { IconName } from '@components/atoms/hero-icon';

const FreeEnergyMarketSection = () => {
  const { isModalOpen, setModalOpen } = useModalContext();
  const isSmallScreen = useScreenSize();
  const retailContainer = twMerge(isSmallScreen ? 'px-0' : 'px-20', style.RetailContainer);

  const retailCardsGroup = twMerge(isSmallScreen ? 'mt-6 gap-4' : 'mt-16 gap-10', style.RetailCardsGroup);
  const ctaButton = twMerge(
    isSmallScreen
      ? 'w-full h-12 flex justify-between w-full h-12 mt-6 mb-[2.125rem] text-paragraph-medium'
      : 'h-14 text-paragraph-large gap-3',
    style.ctaButton,
  );

  const openModal = () => {
    if (!isModalOpen) {
      setModalOpen(true);
    }
  };

  return (
    <section className={style.RetailSection}>
      <div className={retailContainer}>
        <div className="flex flex-col md:flex-row md:justify-between md:items-center">
          <div className={style.RetailTitle}>
            Porque o <span className="text-brand-primary-0 text-nowrap"> Mercado Livre </span> de Energia?
          </div>
        </div>
        <div className={retailCardsGroup}>
          {freeEnergyMarketCardData.map((cardData, idx) => (
            <CardComponent
              key={idx}
              icon={cardData.icon as IconName}
              svgImageUrl={cardData.svgImageUrl}
              title={cardData.title}
              description={cardData.description}
            />
          ))}
        </div>
      </div>
      <div className="order-2">
        <ButtonComponent
          icon="ArrowRightIcon"
          iconPosition="right"
          iconType="solid"
          iconSize="w-[1.275rem]"
          iconStyles={twMerge('w-8 h-8 md:w-10 h-10 bg-brand-light-20 text-brand-primary-70')}
          kind="primaryCustom"
          size="1"
          label="Saber mais"
          className={ctaButton}
          onClick={openModal}
        />
      </div>
    </section>
  );
};

export default FreeEnergyMarketSection;
