import littleBusiness from '@assets/Pequenas Empresas e Comércio.png';
import mediumBusiness from '@assets/Médias Empresas e Varejo.png';
import bigBusiness from '@assets/Grandes Empresas e Indústrias.png';
import { IconName } from '@components/atoms/hero-icon';
import treeIcon from '@assets/tree.svg';

interface ISections {
  label: string;
  content: {
    title: string;
    description: string;
    highlights: { text: JSX.Element; icon?: IconName; svg?: string }[];
    image: string;
  };
}
export const sectionsDetails: ISections[] = [
  {
    label: 'Grandes Empresas e Indústrias',
    content: {
      title: 'Soluções para Grandes Empresas',
      description:
        'A Clarke é uma parceira estratégica para grandes empresas e indústrias que buscam migrar para o mercado livre de energia. Com uma gestão completa e acesso a mais de 70 fornecedores em seu marketplace, a Clarke garante a melhor negociação, ajudando seu negócio a obter as melhores tarifas e um contrato personalizado.',
      highlights: [
        {
          text: (
            <div className="w-[32.34rem]">
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">Redução de custos</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']">
                {' '}
                em escala, permitindo mais competitividade no setor.
              </span>
            </div>
          ),
          icon: 'CurrencyDollarIcon',
        },
        {
          text: (
            <div className="w-[32.625rem]">
              <span className="text-[#222222] text-base font-light font-['Poppins']">Acompanhamento </span>
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">dedicado</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']"> e </span>
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">personalizado</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']">
                , atrelado a tecnologia e agilidade, sempre atrás do melhor negócio.
              </span>
            </div>
          ),
          icon: 'IdentificationIcon',
        },
        {
          text: (
            <div className="w-[32.625rem]">
              <span className="text-[#222222] text-base font-light font-['Poppins']">Acesso a métricas de </span>
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">sustentabilidade</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']">
                {' '}
                que potencializam seus esforços de{' '}
              </span>
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">ESG</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']">.</span>
            </div>
          ),
          svg: treeIcon,
        },
      ],
      image: bigBusiness,
    },
  },
  {
    label: 'Médias Empresas e Varejo',
    content: {
      title: 'Soluções para Médias Empresas',
      description:
        'Para médias empresas e varejo, a Clarke facilita a migração para o mercado livre de energia, oferecendo suporte do começo ao fim. Nosso marketplace de fornecedores permite que você encontre condições ideais e adequadas às necessidades sazonais e operacionais do seu negócio. Nosso acompanhamento pode contar com serviços de ajustes de demanda e análise para escala ou aumento da operação, assim nos tornamos parceiros ideais para o crescimento da sua empresa.',
      highlights: [
        {
          text: (
            <div className="w-[32.34rem]">
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">Economia</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']">
                {' '}
                de energia que libera recursos para{' '}
              </span>
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">reinvestir</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']"> na expansão.</span>
            </div>
          ),
          icon: 'BanknotesIcon',
        },
        {
          text: (
            <div className="w-[32.625rem]">
              <span className="text-[#222222] text-base font-light font-['Poppins']">Atendimento personalizado e </span>
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">sem burocracia</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']">
                , para uma transição tranquila.
              </span>
            </div>
          ),
          icon: 'UsersIcon',
        },
        {
          text: (
            <div className="w-[32.625rem]">
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">Contratos ajustáveis</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']">
                {' '}
                e escolha de fornecedores que combinam com a sazonalidade do seu setor.
              </span>
            </div>
          ),
          icon: 'DocumentCheckIcon',
        },
      ],
      image: mediumBusiness,
    },
  },
  {
    label: 'Pequenas Empresas e Comércio',
    content: {
      title: 'Soluções para Pequenas Empresas',
      description:
        'A Clarke facilita o acesso de pequenas empresas que estejam em média/alta tensão ao mercado livre de energia, promovendo mais economia e previsibilidade para seu fluxo de caixa. Com a nossa plataforma de fornecedores e gestão completa, a migração se torna simples e sem burocracia, deixando sua empresa focada no que importa: crescer.',
      highlights: [
        {
          text: (
            <div className="w-[32.34rem]">
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">Custos</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']"> energéticos </span>
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">sob controle</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']">
                , aliviando as finanças do seu negócio.
              </span>
            </div>
          ),
          icon: 'CurrencyDollarIcon',
        },
        {
          text: (
            <div className="w-[32.625rem]">
              <span className="text-[#222222] text-base font-light font-['Poppins']">Assistência dedicada para </span>
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">eliminar a burocracia</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']"> do processo de migração.</span>
            </div>
          ),
          icon: 'ShieldCheckIcon',
        },
        {
          text: (
            <div className="w-[32.625rem]">
              <span className="text-[#222222] text-base font-light font-['Poppins']">
                Acesso ao marketplace exclusivo da Clarke, com opções{' '}
              </span>
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">vantajosas</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']"> e </span>
              <span className="text-[#00bf6a] text-base font-bold font-['Poppins']">sob medida</span>
              <span className="text-[#222222] text-base font-light font-['Poppins']">.</span>
            </div>
          ),
          icon: 'ComputerDesktopIcon',
        },
      ],
      image: littleBusiness,
    },
  },
];
