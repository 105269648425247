import treeWhiteIcon from '@assets/tree-white.png';

export const freeEnergyMarketCardData = [
  { icon: 'CurrencyDollarIcon', title: 'Economia', description: 'Reduza em até 40% a conta de luz da sua empresa.' },
  {
    icon: 'LockOpenIcon',
    title: 'Liberdade',
    description:
      'Abandone os preços das distribuidoras e seja livre para escolher o fornecedor que oferece as melhores vantagens para o seu negócio.',
  },
  {
    svgImageUrl: treeWhiteIcon,
    title: 'Sustentabilidade',
    description: 'Contrate energia limpa sem custo adicional e aproxime sua empresa das práticas ESG.',
  },
  {
    icon: 'ArrowPathRoundedSquareIcon',
    title: 'Flexibilidade',
    description: 'Tenha acesso a contratos personalizados de acordo com a realidade e necessidade da sua empresa.',
  },
  {
    icon: 'ShieldCheckIcon',
    title: 'Segurança',
    description:
      'O Mercado Livre de Energia é regulado por órgãos competentes que estabelecem normas e garantias para proteger os consumidores.',
  },
  {
    icon: 'EyeIcon',
    title: 'Transparência',
    description:
      'Tenha na mão informações sobre preços, condições de fornecimento e desempenho dos fornecedores em uma modalidade de contratação que oferece concorrência.',
  },
];
