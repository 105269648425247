import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import ButtonComponent from '@components/atoms/button/button';
import { useScreenSize } from '@hooks/useScreenSize';
import { useModalContext } from '@contexts/modalContext';
export function HeaderSection() {
  const { isModalOpen, setModalOpen } = useModalContext();
  const openModal = () => {
    if (!isModalOpen) {
      setModalOpen(true);
    }
  };
  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isModalOpen]);
  const isSmallScreen = useScreenSize();
  return (
    <div className="py-10 xl:py-20 xl:px-0 flex flex-col h-full w-[29rem] gap-10 items-start lg:justify-evenly">
      <div className="flex flex-col gap-6 ">
        <span className="w-80 lg:w-[24rem] text-neutral-50 text-heading-large lg:text-heading-xxLarge font-bold leading-8 lg:leading-[3.375rem]">
          Ainda não sabe se a Clarke pode ajudar a sua empresa?
        </span>
        <span className="w-80 lg:w-[20.1875rem] text-neutral-50 text-paragraph-medium leading-normal">
          Faça uma <b>análise 100% gratuita</b> com nossos especialistas e descubra se o seu negócio pode migrar Mercado
          Livre de Energia e qual o melhor caminho!
        </span>
      </div>
      <ButtonComponent
        icon="ArrowRightIcon"
        iconPosition="right"
        iconType="solid"
        iconStyles="w-10 h-10 bg-brand-light-20 text-brand-primary-70"
        kind="primaryCustom"
        size="4"
        label="Receber análise sem custo"
        className={twMerge(
          isSmallScreen ? 'min-w-full w-80 h-12 text-left' : 'w-96 text-center',
          'flex gap-5 text-neutral-50 text-center text-nowrap lg:text-paragraph-large font-normal leading-normal´',
        )}
        onClick={openModal}
      />
    </div>
  );
}
