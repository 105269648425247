import React from 'react';

interface ImageSectionProps {
  src: string;
  alt: string;
}

export const ImageSection: React.FC<ImageSectionProps> = ({ src, alt }) => (
  <div className="lg:w-[27.9169rem] lg:h-[31.375rem]">
    <img src={src} alt={alt} className="min-size-full" />
  </div>
);
