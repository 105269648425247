import React from 'react';
import HeroIcon, { IconName } from '@components/atoms/hero-icon';
import { useModalContext } from '@contexts/modalContext';
interface SectionContentProps {
  description: string;
  highlights: { text: JSX.Element; icon?: IconName; svg?: string }[];
}

export const SectionContent: React.FC<SectionContentProps> = ({ description, highlights }) => {
  const { isModalOpen, setModalOpen } = useModalContext();
  const openModal = () => {
    if (!isModalOpen) {
      setModalOpen(true);
    }
  };
  React.useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isModalOpen]);
  return (
    <div className="flex flex-col gap-8 max-w-full p-2">
      <div className="text-[#222222] text-base font-light font-['Poppins'] min-h-40">{description}</div>
      <div className="flex flex-col gap-8">
        {highlights.map((item, index) => (
          <div
            key={index}
            className=" flex flex-row-reverse max-w-full lg:w-[36.3125rem] h-fit lg:h-12 gap-4 justify-center items-center"
          >
            {item.text}
            <div className="bg-[#e5f8dc] rounded-[.625rem] h-12 p-2 flex items-center">
              <div className="size-8">
                {item.icon ? (
                  <HeroIcon type="solid" icon={item.icon} className="size-8 text-brand-primary-60" />
                ) : (
                  <img src={item.svg} alt="icon" />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        onClick={() => openModal()}
        className="absolute bottom-0 text-[#00bf6a] text-sm font-bold font-['Poppins'] underline inline-flex justify-center items-center gap-1 cursor-pointer"
      >
        Veja como a clarke ajuda o seu negócio{' '}
        <HeroIcon type="solid" icon="ArrowRightIcon" className="size-4 text-brand-primary-60" />
      </div>
    </div>
  );
};
