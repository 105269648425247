import React from 'react';

interface ButtonSelectorProps {
  isActive: boolean;
  label: string;
  onClick: () => void;
}

export const ButtonSelector: React.FC<ButtonSelectorProps> = ({ isActive, label, onClick }) => (
  <div
    className={`w-full px-6 py-4 rounded-[31.25rem] border-2 ${
      isActive ? 'bg-[#00bf6a] border-[#00bf6a]' : 'border-[#e8e8e8]'
    } flex justify-center items-center gap-2.5 cursor-pointer h-8 lg:h-14`}
    onClick={onClick}
  >
    <button
      className={`text-lg font-${isActive ? 'bold' : 'light'} font-['Poppins'] ${
        isActive ? 'text-neutral-50' : 'text-[#00bf6a]'
      }`}
    >
      {label}
    </button>
  </div>
);
